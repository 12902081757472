import './AccordeonComponentStyles.scss';
import { FC, useState } from "react";
import AccordeonComponentProps from "./AccordeonComponentProps";
import Icons from "../../assets/Icons";

const AccordeonComponent: FC<AccordeonComponentProps> = ({ title, subtitle, options }) => {
    const [open, setOpen] = useState<number | undefined>(undefined);

    const _handleOpen = (index: number) => {
        if (open == index) setOpen(undefined);
        else setOpen(index);
    }

    return <div className="accordeon_component">
        <div className="w-100">
            {title && (
                <div className="side side_top">
                    {title}
                </div>
            )}
            <div className="container p-0">
                {subtitle &&
                    <div className="pb-5">
                        {subtitle}
                    </div>}
                {options.map((option, index) => {
                    if (option?.showOption ?? true) {
                        return (
                            <div className={`item ${index == open && 'active'}`} key={index}>
                                <div className="header" onClick={() => _handleOpen(index)}>
                                    {typeof option.title == 'string' ? <h5 className="title ms-3">{option.title}</h5> : option.title}
                                    <div className='me-3'>
                                        {index == open ? <Icons.Clear height={25} width={25} /> : <Icons.Plus height={25} width={25} />}
                                    </div>
                                </div>
                                <div className="content py-3 mx-3 mx-md-4">{option.content}</div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    </div>
}

export default AccordeonComponent;