import Button from "../../../../../components/Button";
import "./cardStyles.scss";

export const Card = ({ cardInfo, setShowGarantieModal, setSrvCode }) => {
    return (
        <div className="w-100" style={{ background: cardInfo.cardColor }}>
            <div
                className="card_container container"
                style={{
                    flexDirection: cardInfo.directionReverse ? "row-reverse" : "row",
                    color: cardInfo.textColor,
                    width: cardInfo.cardWidth,
                }}
            >
                <img
                    src="./assets/home/car_05.svg"
                    alt="wcar"
                    title="wcar"
                    className="svg_wcar"
                    style={{ right: !cardInfo.directionReverse && "0px", left: cardInfo.directionReverse && "0px" }}
                />
                <div className="card_part_one sub_title_2 mt-5  mx-0 er">
                    <h2>
                        {cardInfo.titleBlack} <i>{cardInfo.titleOrange}</i>
                    </h2>
                    <div className="item_list">
                        <img src="./assets/icons/check-white.png" className={"mt-2"} alt="wcar" />
                        <p className={"fs-5"}>{cardInfo.paragraphOne}</p>
                    </div>
                    <div className="item_list mt-2">
                        <img src="./assets/icons/check-white.png" className={"mt-2"} alt="wcar" />
                        <p className={"fs-5"}>{cardInfo.paragraphTwo}</p>
                    </div>
                    <div className="item_list mt-2">
                        <img src="./assets/icons/check-white.png" className={"mt-2"} alt="wcar" />
                        <p className={"fs-5"}>{cardInfo.paragraphThree}</p>
                    </div>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowGarantieModal(true);
                            setSrvCode("1003");
                        }}
                        label={cardInfo.buttonText}
                    ></Button>
                </div>
                <div className="card_part_two">
                    <img src={cardInfo.imgUrl} alt="wcar" className="card_phone_img" />
                </div>
            </div>
        </div>
    );
};
