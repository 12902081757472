import { Link } from "react-router-dom";
import Button from "../Button";
import "./modalStyles.scss";

export const ModalNotAvailable = ({ setAlert, setOpenForm }) => {
    return (
        <>
            <div className="modal_container position-relative">
                <div
                    onClick={() => setAlert(false)}
                    className="position-absolute"
                    style={{ top: "30px", right: "30px", cursor: "pointer" }}
                >
                    <img src="./assets/icons_contact/close_icon.svg" alt="" loading="lazy" decoding="async" />
                </div>
                <div className="top">
                    <img
                        src="./assets/icons_contact/not_success_icon.svg"
                        alt="success_icon"
                        loading="lazy"
                        decoding="async"
                    />
                </div>
                <div className="middle">
                    <p className="text-center">
                        <strong className="mb-3">¡Gracias por elegir WCAR!</strong>
                    </p>
                    <br />
                    <p className="text-center">
                        Hemos recibido tu información y estamos emocionados de acompañarte en esta experiencia. Nuestro
                        equipo se pondrá en contacto contigo muy pronto para seguir adelante. ¡Estamos encantados de
                        ayudarte!
                    </p>
                </div>
                <div className="bottom gap-4 ">
                    <Button
                        // className="btn btn_orange_transparent"
                        onClick={() => {
                            setAlert(false);
                            setOpenForm(true);
                        }}
                        variant="tertiary"
                        label="VOLVER"
                    />
                    {/* <a
                        href="https://calendar.app.google/1SDLbgLoKa88iNv39"
                        target="_blank"
                        className="btn btn_orange ms-3"
                        onClick={() => setAlert(false)}
                    >
                        Agendar una llamada
                    </a> */}
                    <Link to="https://calendar.app.google/1SDLbgLoKa88iNv39" target="_blank">
                        <Button label="Agendar una llamada" onClick={() => setAlert(false)} />
                    </Link>
                </div>
            </div>
        </>
    );
};
